'use client';
import usePullToRefresh from '@/hooks/usePulltoRefresh';
import { cn } from '@/lib/utils';
import React, { PropsWithChildren, useRef } from 'react';

interface MaxWidthWrapperProps extends PropsWithChildren {
    className?: string;
}

const MaxWidthWrapper = ({ children, className }: MaxWidthWrapperProps) => {
    const pullRefreshRef = useRef<HTMLDivElement>(null);
    const state = usePullToRefresh(pullRefreshRef, () => window.location.reload());
    return (
        <div
            ref={pullRefreshRef}
            className={cn('mx-auto flex w-full max-w-(--breakpoint-xl) flex-1 flex-col px-4 py-2', className)}>
            {children}
        </div>
    );
};

export default MaxWidthWrapper;
