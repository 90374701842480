import { useEffect } from 'react';

const TRIGGER_THRESHOLD = 400;
const SHOW_INDICATOR_THRESHOLD = 300;

function usePullToRefresh(ref: React.RefObject<HTMLDivElement | null>, onTrigger: () => void) {
    useEffect(() => {
        if (!ref.current) return;
        const el = ref.current;
        if (!el) return;

        // attach the event listener
        el.addEventListener('touchstart', handleTouchStart);

        function handleTouchStart(startEvent: TouchEvent) {
            const el = ref?.current;
            if (!el) return;
            if (el.scrollTop > 0) return;

            // get the initial Y position
            const initialY = startEvent.touches[0].clientY;

            el.addEventListener('touchmove', handleTouchMove);
            el.addEventListener('touchend', handleTouchEnd);

            function handleTouchMove(moveEvent: TouchEvent) {
                const el = ref.current;
                if (!el) return;

                // get the current Y position
                const currentY = moveEvent.touches[0].clientY;

                // get the difference
                const dy = currentY - initialY;

                if (dy < 0) return;

                // now we are using the `appr` function
                el.style.transform = `translateY(${appr(dy)}px)`;
                const parentEl = el.parentNode as HTMLDivElement;
                if (dy > TRIGGER_THRESHOLD) {
                    flipArrow(parentEl);
                } else if (dy > SHOW_INDICATOR_THRESHOLD) {
                    addPullIndicator(parentEl);
                } else {
                    removePullIndicator(parentEl);
                }
            }

            // more code

            const MAX = 128;
            const k = 0.4;
            function appr(x: number) {
                return MAX * (1 - Math.exp((-k * x) / MAX));
            }

            function handleTouchEnd(endEvent: TouchEvent) {
                const el = ref.current;
                if (!el) return;

                // return the element to its initial position
                el.style.transform = 'translateY(0)';

                // add transition
                el.style.transition = 'transform 0.2s';

                const y = endEvent.changedTouches[0].clientY;
                const dy = y - initialY;
                if (dy > TRIGGER_THRESHOLD) {
                    onTrigger();
                }

                // listen for transition end event
                el.addEventListener('transitionend', onTransitionEnd);

                // cleanup
                el.removeEventListener('touchmove', handleTouchMove);
                el.removeEventListener('touchend', handleTouchEnd);

                removePullIndicator(el.parentNode as HTMLDivElement);
            }

            function onTransitionEnd() {
                const el = ref.current;
                if (!el) return;

                // remove transition
                el.style.transition = '';

                // cleanup
                el.removeEventListener('transitionend', onTransitionEnd);
            }
        }

        function addPullIndicator(el: HTMLDivElement) {
            const indicator = el.querySelector('.pull-indicator');
            if (indicator) {
                // already added

                // make sure the arrow is not flipped
                if (indicator.classList.contains('flip')) {
                    indicator.classList.remove('flip');
                }
                return;
            }

            const pullIndicator = el.appendChild(document.createElement('div'));
            pullIndicator.className = 'pull-indicator flex flex-col gap-2 absolute top-4 z-ultr left-1/2 -translate-x-1/2 p-2 text-black';
            pullIndicator.innerHTML = `
                <div>
                    <div class='bg-white rounded-full p-3 transition-transform duration-200'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                        </svg>
                    </div>
                </div>
            `;
        }

        function removePullIndicator(el: HTMLDivElement) {
            const pullIndicator = el.querySelector('.pull-indicator');
            if (pullIndicator) {
                pullIndicator.remove();
            }
        }

        function flipArrow(el: HTMLDivElement) {
            const pullIndicator = el.querySelector('.pull-indicator');
            if (pullIndicator && !pullIndicator.classList.contains('refresh-rotate')) {
                pullIndicator.classList.add('refresh-rotate');
            }
        }

        return () => {
            // let's not forget to cleanup
            el.removeEventListener('touchstart', handleTouchStart);
        };
    }, [ref.current]);
}
export default usePullToRefresh;
